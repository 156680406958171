export const accordionData = [
    {
        num:"/001",
        title:"photoshop",
        content:"La connaissance de Photoshop permet de créer des contenus personnalisés et d'ajuster les images pour un site web moderne."
    },
    {
        num:"/002",
        title:"Figma",
        content:" Utilisation de Figma afin de créer les designs avant de commencer a entrer dans le code."
    },
    {
        num:"/003",
        title:"HTML",
        content:" Connaissance des balises sémantiques du HTML5 pour une meilleure indexation des moteurs de recherche."
    },
    {
        num:"/004",
        title:"CSS",
        content:" Le Css pour créer de beaux sites internet a l'apparence soignée."
    },
    {
        num:"/005",
        title:"Javascript",
        content:" La maîtrise de JavaScript permet d'animer un site, de créer du contenu mis à jour dynamiquement et d'utiliser des API."
    },
    {
        num:"/006",
        title:"Wordpress",
        content:` Pour créer des sites rapidement, à gestion autonome, avec différentes fonctionnalités.
        La connaissance d'Elementor permet de créer des designs personnalises.`
    },
    {
        num:"/007",
        title:"React",
        content:` Permet de créer de grandes interfaces utilisateur riche en fonctionnalités, rapide et organiser grâce au composant.`
    },
]
