import img1 from '../Files/Img/1.jpg';
import img2 from '../Files/Img/2.jpg';
import img3 from '../Files/Img/3.jpg';
import img4 from '../Files/Img/4.jpg';
import React, { useRef, useEffect } from 'react';

import { gsap, Expo } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
gsap.registerPlugin(ScrollTrigger);
export default function About() {
	useEffect(() => {
		const gsapItem = gsap.utils.toArray('.reveal_img-item');
		gsapItem.forEach((gsIt) => {
			const imgNum = gsIt.querySelector('.reveal_img-num');
			const imgInner = gsIt.querySelector('.reveal_img-inner');
			const imgBl = gsIt.querySelector('.reveal_img-bl');
			const imgImg = gsIt.querySelector('.reveal_img-img');
			let tl = gsap.timeline({
				scrollTrigger: {
					trigger: gsIt,
					start: 'top 100%',
					end: 'bottom 10%',
					toggleActions: 'restart pause resume pause',
				},
			});

			tl.to(
				imgInner,
				{
					clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
					ease: 'expo.out',
					duration: 0.5,
				},
				'-=1'
			);
			tl.to(
				imgBl,
				{
					clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
					ease: 'expo.out',
					duration: 1.5,
				},
				'-=0.5'
			);
		});
	}, []);

	return (
		<>
			<div
				id="about"
				className="about_wrapp">
				<section className="reveal">
					<h1 className="about_title">
						<span className="title_letter_blink">W</span>
						<span className="title_letter_blink">O</span>
						<span className="title_letter_blink">R</span>
						<span className="title_letter_blink">K</span>
						<span className="title_letter_blink">S</span>
					</h1>
					<div className="reveal_wrapp">
						<div className="reveal_img">
							<div className="reveal_img-item">
								<span
									id="test"
									className="reveal_img-num">
									001
								</span>

								<div className="reveal_img-inner">
									<div className="reveal_img-bl">
										<img
											src={img1}
											alt="1"
											className="reveal_img-img"
										/>
									</div>
								</div>
							</div>

							<div className="reveal_img-item">
								<span className="reveal_img-num">002</span>
								<div className="reveal_img-inner">
									<div className="reveal_img-bl">
										<img
											src={img2}
											alt="2"
											className="reveal_img-img"
										/>
									</div>
								</div>
							</div>
							<div className="reveal_img-item">
								<span className="reveal_img-num">003</span>
								<div className="reveal_img-inner">
									<div className="reveal_img-bl">
										<img
											src={img3}
											alt="3"
											className="reveal_img-img"
										/>
									</div>
								</div>
							</div>
							<div className="reveal_img-item">
								<span className="reveal_img-num">004</span>
								<div className="reveal_img-inner">
									<div className="reveal_img-bl">
										<img
											src={img4}
											alt="4"
											className="reveal_img-img"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
