
import { Route, Routes } from 'react-router';
import './App.css';
import Contact from './Pages/Contact';
import Home from './Pages/Home';


function App() {
  return (
    <div className="App">
      <div className='wrapp'>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/contact' element={<Contact/>} />
        </Routes>
      
        
      </div>
      
    </div>
  );
}

export default App;
