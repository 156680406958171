import { Link } from 'react-router-dom';

function Footer() {
	return (
		<section className="footer">
			<div className="footer_top_wrapper">
				<h3>AM</h3>
				<ul>
					<li>
						<Link to="https://www.instagram.com/alejandro_mb93"> instagram</Link>
					</li>
					<li>
						<Link to="https://github.com/AlexMaunouryBenavides">github</Link>
					</li>
				</ul>
			</div>
			<div className="footer_bottom_wrapper">© Maunoury Alexandre 2023</div>
		</section>
	);
}

export default Footer;
