import React, { useState, useEffect } from 'react';
import plus from '../Files/Img/plus.png';
import minus from '../Files/Img/minus.png';

export default function Accordion({ num, title, content, isOpen, onToggle }) {
	const [isAccordionOpen, setIsAccordionOpen] = useState(isOpen);

	useEffect(() => {
		setIsAccordionOpen(isOpen);
	}, [isOpen]);

	const handleAccordionClick = () => {
		onToggle();
	};

	return (
		<div className={`accordion ${isAccordionOpen ? 'open' : ''}`}>
			<div className="accordion_title" onClick={handleAccordionClick}>
				<p>{num}</p>
				<h4>{title}</h4>
				<span className="icon">{isAccordionOpen ? <img src={minus} alt="ouvrir" /> : <img src={plus} alt="fermer" />}</span>
			</div>

			<div className="accordion_content">
				<div className="content">
					<p>{content}</p>
				</div>
			</div>
		</div>
	);
}
