import { React, useState } from 'react';
import Arrow from '../Files/Img/arrow-down.png';
import Ellipses from '../Files/Img/ellipses.png';
import About from '../Component/About';
import Accordion from '../Component/Accordion';
import { accordionData } from '../Services/AccordionData';
import { Link } from 'react-router-dom';
import Footer from '../Component/Footer';

export default function Home() {
	const [openAccordion, setOpenAccordion] = useState(null);

	const handleAccordionClick = (index) => {
		if (index === openAccordion) {
			// Si l'accordéon actuellement cliqué est déjà ouvert, fermez-le.
			setOpenAccordion(null);
		} else {
			// Sinon, ouvrez le nouvel accordéon et fermez l'ancien.
			setOpenAccordion(index);
		}
	};

	return (
		<>
			<header className="home">
				<div className="home_header">
					<div className="home_header_logo">
						<h2>AM</h2>
					</div>
					<div className="home_header_nav"> NAV </div>
				</div>
				<div className="home_main">
					<div className="home_main_content">
						<h1 className="home_title">
							<span className="lastname">
								<span className="title_letter_blink">M</span>
								<span className="title_letter_blink">A</span>
								<span className="title_letter_blink">U</span>
								<span className="title_letter_blink">N</span>
								<span className="title_letter_blink">O</span>
								<span className="title_letter_blink">U</span>
								<span className="title_letter_blink">R</span>
								<span className="title_letter_blink">Y</span>
							</span>
							<span className="firstname">
								<span className="title_letter_blink">A</span>
								<span className="title_letter_blink">L</span>
								<span className="title_letter_blink">E</span>
								<span className="title_letter_blink">X</span>
								<span className="title_letter_blink">A</span>
								<span className="title_letter_blink">N</span>
								<span className="title_letter_blink">D</span>
								<span className="title_letter_blink">R</span>
								<span className="title_letter_blink">E</span>
							</span>
							<span className="job">
								<span className="title_letter_blink">F</span>
								<span className="title_letter_blink">R</span>
								<span className="title_letter_blink">O</span>
								<span className="title_letter_blink">N</span>
								<span className="title_letter_blink">T</span>
								<span className="title_letter_blink">-</span>
								<span className="title_letter_blink">E</span>
								<span className="title_letter_blink">N</span>
								<span className="title_letter_blink">D</span>
								<span className="title_letter_blink">-</span>
								<span className="title_letter_blink">D</span>
								<span className="title_letter_blink">E</span>
								<span className="title_letter_blink">V</span>
							</span>
						</h1>
					</div>
					<div className="home_main_img">
						<img src={Ellipses} alt="home-ellipses-img" />
					</div>
				</div>
				<div className="home_footer">
					<nav className="home_footer_content">
						<ul>
							<li>
								<Link to="https://www.instagram.com/alejandro_mb93">Instagram</Link>
							</li>
							<li>
								<Link to="https://github.com/AlexMaunouryBenavides">GitHub</Link>
							</li>
						</ul>
					</nav>
					<div className="home_arrow">
						<a href="#about">
							<img src={Arrow} alt="arrow-down" />
						</a>
					</div>
					<div className="home_contact">
						<p>
							<Link to={'/contact'}>Contact</Link>
						</p>
					</div>
				</div>
			</header>
			<About />
			<section id="accordion" className="thirdSection">
				<ul>
					{accordionData.map((accordion, index) => (
						<Accordion key={index} num={accordion.num} title={accordion.title} content={accordion.content} onToggle={() => handleAccordionClick(index)} isOpen={openAccordion === index} />
					))}
				</ul>
			</section>

			<Footer />
		</>
	);
}
