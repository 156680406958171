import React, { useRef } from 'react';
import Header from '../Component/Header';
import emailjs from '@emailjs/browser';

export default function Contact() {
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs.sendForm('service_03ke3ka', 'template_nxhjyef', form.current, 'sHREinMtS34QjeaPA').then(
			(result) => {
				console.log(result.text);
			},
			(error) => {
				console.log(error.text);
			}
		);
	};
	return (
		<div className="contact">
			<Header />
			<div className="contact_main">
				<form ref={form} onSubmit={sendEmail} className="container">
					<div className="form-group">
						<input id="nom" name="user_name" className="form-control" type="text" required />
						<label htmlFor="nom">Nom*</label>
					</div>
					<div className="form-group">
						<input id="prenom" name="user_email" className="form-control" type="text" />
						<label htmlFor="prenom">Prénom*</label>
					</div>

					<div className="form-group">
						<textarea id="message" name="message" className="form-control" required />
						<label htmlFor="message">Message</label>
					</div>

					<button type="submit">Envoyez</button>
				</form>
			</div>
		</div>
	);
}
