import React from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
	return (
		<>
			<div className="home_header">
				<div className="home_header_logo">
					<Link to={'/'}>
						<h2>AM</h2>
					</Link>
				</div>
				<div className="home_header_nav"> NAV </div>
			</div>
		</>
	);
}
